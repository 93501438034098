$(document).foundation();

gsap.registerPlugin(ScrollTrigger);
gsap.set(".massiveImage", {backgroundImage: `url(/img/cvs_health/Cross_functional_diagram.png)`})
gsap.to(".massiveImage", {
  xPercent: -100, 
  x: () => innerWidth,
  ease: "none",
  scrollTrigger: {
    trigger: ".massiveImage",
    start: "center center",
    end: () => innerWidth * 3,
    scrub: true,
    pin: true,
    invalidateOnRefresh: true,
    anticipatePin: 1
  }
});
$(document)
        .ready(function () {
            //initialize paroller.js
            $('.my-paroller').paroller();  
            $('.jumbotron').paroller();
            $('[data-paroller-factor]').paroller();
            $('.paroller-example').paroller({
                factorXs: 0.1,
                factorSm: 0.1,
                factorMd: -0.4,
                factorLg: -0.5,
                factorXl: -0.6,
                factor: -0.4,
                type: 'foreground',
                direction: 'horizontal'
            });
        });




// function myFunction() {
//    var element = document.body;
//    element.classList.toggle("dev-mode");
// }

// // Get the id of the <path> element and the length of <path>
// var triangle = document.getElementByClass("triangle");
// var length = triangle.getTotalLength();

// // The start position of the drawing
// triangle.style.strokeDasharray = length;

// // Hide the triangle by offsetting dash. Remove this line to show the triangle before scroll draw
// triangle.style.strokeDashoffset = length;

// // Find scroll percentage on scroll (using cross-browser properties), and offset dash same amount as percentage scrolled
// window.addEventListener("scroll", myFunction);

// function myFunction() {
//   var scrollpercent = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);

//   var draw = length * scrollpercent;

//   // Reverse the drawing (when scrolling upwards)
//   triangle.style.strokeDashoffset = length - draw;
// }
    
